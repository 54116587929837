@import url(https://fonts.googleapis.com/css2?family=Rakkas&display=swap);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rakkas&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* desktop only css */

@media only screen and (min-width: 920px) {
  .rakkas {
    font-family: Rakkas;
  }
  .mobile-only {
    display: none;
  }

  body {
  }

  /*Laptop screens*/
  .metamask-btn:hover {
    color: white;
    background-color: rgb(247, 80, 51);
  }

  .btn:hover {
    color: white;
  }

  #main {
    text-align: center;
    width: 100%;
    margin: auto;
  }

  /*  Navigation menu css  */
  .menu {
    font-family: Rakkas;
    margin: auto;
    overflow: hidden;
    color: #1e266d;
    background: #b5f2e3;
    height: 85px;

    transition: 0.5s;
  }

  header {
    background: #b5f2e3;
  }

  .transparent {
    opacity: 0.9;
  }

  .menu-items {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
  }

  .menu-items {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 85px;
    /* or 472% */

    letter-spacing: 0.095em;

    color: #1e266d;
  }

  .menu-items li {
    list-style: none;
    font-weight: bolder;
  }

  .menu-items li a {
    text-decoration: none;
    color: #44598b;
    font-weight: normal;
  }

  .menu-items li a:hover {
    text-decoration: underline;
    color: #ff2e6a;
  }

  .menu-items li a:active {
    color: rgb(246, 70, 121);
  }

  .menu-items li a:focus {
    color: rgb(246, 70, 121);
  }

  .link-redirect {
    text-decoration: none;
  }

  .metamask-btn {
    font-family: PT Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    margin-left: 10px;
    line-height: 40px;
    /* identical to box height, or 222% */

    text-align: center;
    text-transform: uppercase;

    color: #ffffff;
    background: #fa7106;
    border-radius: 100px;
    width: 211px;
    height: 50px;
    border: none;
    padding: 5 px;
  }

  /*  -----------------------------------------

                
   
                timer css goes here



  --------------------------------------------- */

  .timer {
    height: 100%;

    margin-bottom: -70px;
  }

  .timmer-inner p {
    font-family: Rakkas;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 150%;
    /* or 54px */

    text-align: center;
    color: rgb(255, 111, 119);
  }

  /*  Home css  */

  #home-inner {
    width: 1425px;
    height: 962px;
    margin: auto;

    text-align: center;
    background: rgb(255, 236, 145);
    background: linear-gradient(180deg, #fff49a 0%, #ffe083 100%);
    border-radius: 0px;
    height: 100%;
    left: 0px;
    top: -84px;
    border-radius: 0px;
  }

  #home {
    width: 100%;
    background: rgb(255, 236, 145);
    background: linear-gradient(180deg, #fff49a 0%, #ffe083 100%);
    border-radius: 0px;
    margin-top: -20px;
  }

  .home-h1 {
    padding-top: 6vh;
    font-family: Rakkas;
    font-style: normal;
    font-weight: normal;
    font-size: 96px;
    line-height: 105%;
    /* identical to box height, or 101px */

    text-align: center;

    color: #1e266d;
  }

  .home-h2 {
    font-family: Rakkas;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 105%;
    /* or 50px */

    text-align: center;

    color: #1e266d;
  }

  .home-minting-btn {
    cursor: pointer;
    background: rgba(255, 46, 106, 0.66);
    border-radius: 100px;
    margin-top: 3.5vh;
    margin-bottom: 3.5vh;
    font-family: Rakkas;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 150%;
    /* identical to box height, or 36px */

    text-align: center;
    border: none;
    color: #ffffff;
    width: 324px;
    height: 42px;
    transition: 0.3s;
  }

  .minting-ended-container .h-minting-ended-heading {
    font-family: PT Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 150%;
    /* or 54px */

    text-align: center;

    color: #455880;
  }

  .minting-ended-container .h-minting-ended-subheading {
    font-family: PT Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 150%;
    /* identical to box height, or 27px */

    color: #455880;
  }

  .minting-ended-container .opensea-img {
    width: 79px;
    height: 79px;
    margin-bottom: 2rem;
    cursor: pointer;
  }

  .home-minting-btn:hover {
    color: #ff6f77;

    background: #ffffff;
    border: 1px solid #ff6f77;
    box-sizing: border-box;
    box-shadow: 0px 4px 20px rgba(104, 109, 224, 0.5);
    border-radius: 50px;
  }

  .home-minting-btn:active {
    color: #ff6f77;

    background: #ffffff;
    box-shadow: 0px 4px 20px #ff6f77;
    border-radius: 50px;
  }

  .home-discord-btn {
    cursor: pointer;
    border-radius: 100px;
    margin-top: 3.5vh;
    margin-bottom: 3.5vh;
    font-family: Rakkas;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 150%;
    /* identical to box height, or 36px */
    background: #3751ff;
    text-align: center;
    border: none;
    color: #ffffff;
    width: 324px;
    height: 42px;
    transition: 0.3s;
  }

  .fa-check {
    text-align: center;
    border-radius: 5px;
    position: relative;
    bottom: 22px;
  }

  .home-discord-btn:hover {
    color: #3751ff;

    background: #ffffff;
    border: 1px solid #3751ff;
    box-sizing: border-box;
    box-shadow: 0px 4px 20px rgba(104, 109, 224, 0.5);
    border-radius: 50px;
  }

  .home-discord-btn:active {
    color: #3751ff;
    background: #ffffff;
    box-shadow: 0px 4px 20px #3751ff;
    border-radius: 50px;
  }

  .btn-article {
    width: 165px;
    height: 41px;
    border: none;
    color: white;
    background: #3751ff;
    border-radius: 100px;
  }

  .btn-article:hover {
    color: #3751ff;

    background: #ffffff;
    border: 1px solid #3751ff;
    box-sizing: border-box;
    box-shadow: 0px 4px 20px rgba(104, 109, 224, 0.5);
    border-radius: 50px;
  }

  .btn-article:active {
    color: #3751ff;
    background: #ffffff;
    box-shadow: 0px 4px 20px #3751ff;
    border-radius: 50px;
  }

  .w-d {
    width: 90%;
  }

  .home-pricing-text {
    font-family: PT Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 150%;
    /* or 54px */

    text-align: center;

    color: #455880;
  }

  .home-gas-text {
    font-family: PT Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 150%;
    /* or 21px */

    text-align: center;

    color: #455880;
  }

  .mad-main-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 5rem;
  }
  .mad-main-flex .mad-main-two {
    margin-left: 100px;
  }

  .home-minting-input input {
    width: 109.51px;
    background: #ffffff;
    height: 57.4px;
    border: none;
    text-align: center;
    color: #3751ff;
    font-family: PT Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 150%;
    border-radius: 4px;
  }
  .home-minting-input input:focus {
    border: none;
  }

  .home-minting-input button {
    font-family: Rakkas;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 100%;
    /* or 48px */
    margin: 2rem 0rem;
    border: none;

    text-align: center;

    color: #ffffff;

    background: #3751ff;
    border-radius: 100px;
    height: 60px;
    width: 334px;

    border-radius: 100px;
  }
  .home-minting-input button:hover {
    color: #3751ff;

    background: #ffffff;
    border: 1px solid #3751ff;
    box-sizing: border-box;
    box-shadow: 0px 4px 20px rgba(104, 109, 224, 0.5);
    border-radius: 50px;
  }

  .home-minting-input button:active {
    color: #3751ff;
    background: #ffffff;
    box-shadow: 0px 4px 20px #3751ff;
    border-radius: 50px;
  }

  .home-minus {
    margin-right: 3rem;
    background: #b5f2e3;
    height: 33.999691009521484px;
    width: 33.999691009521484px;
    border-radius: 50%;
    color: red;
    padding: 8px;
    cursor: pointer;
  }

  .home-plus {
    margin-left: 3rem;
    cursor: pointer;
    background: #b5f2e3;
    height: 33.999691009521484px;
    width: 33.999691009521484px;
    border-radius: 50%;
    color: royalblue;
    padding: 8px;
  }
  .home-m-cards {
    width: 75%;
    margin: auto;
    display: flex;
    flex-wrap: nowrap;

    justify-content: space-around;
    margin-top: 1rem;

    margin-bottom: 3rem;
  }

  .home-m-card-item {
    font-family: PT Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 27px;
    /* or 169% */

    text-align: center;

    color: rgba(69, 88, 128, 0.5);
    width: 206px;
    height: 60px;
    padding: 5px;
    border-radius: 20px;
    background: #ffe083;
    margin-top: 20px;
  }

  .home-m-card-item p {
    margin-top: -25px;
  }

  .home-m-card-item > .p-center {
  }

  /*  -----------------------------------------

                
   
                community css goes here



  --------------------------------------------- */

  .community-page {
    width: 100%;
    padding: 3rem 0rem;
    background-color: #dff8ef;
  }

  .community-page-inner {
    width: 1425px;
    margin: auto;
  }

  .community-page-inner h1 {
    font-family: Rakkas;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 79px;
    /* identical to box height, or 165% */

    text-align: center;

    color: #1e266d;
  }

  .community-page-message {
    font-family: PT Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    /* or 150% */

    text-align: center;

    color: #455880;
  }

  .c-notion-page {
    color: #ff6f77;
    text-decoration: none;
    padding-left: 5px;
  }

  /*  -----------------------------------------

                
   
                what css goes here



  --------------------------------------------- */

  #what-inner {
    width: 1425px;
    margin: 0 auto;
    font-family: Rakkas;
    height: auto;
    background: linear-gradient(180deg, #fff49a, #ffe083);
  }

  #what {
    background: linear-gradient(180deg, #fff49a, #ffe083);
  }

  .what-heading {
    text-align: center;
    color: #1e266d;
    padding-top: 20px;
    margin-bottom: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 79px;
  }

  .what-row {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .what-row-col-2 {
    margin-top: 1rem;
    align-self: center;
    text-align: left;
    /* or 150% */
    width: 421px;
    color: #455880;
  }

  .what-plan-heading {
    font-family: PT Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 125%;
    /* or 27px */
    width: 300px;

    color: #1e266d;
  }

  .what-plan-equal-p {
    font-family: PT Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 27px;
    /* or 150% */
    width: 300px;

    color: #455880;
  }

  .bgv {
    width: 519px;
    height: 681px;
  }

  .dude_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;
    margin-top: 100px;
  }

  .dude_one,
  .dude_two,
  .dude_three {
    height: 195px;
    width: 585px;

    border-radius: 0px;
  }

  .plane-one,
  .plane-two,
  .plane-three {
    background: #aedeff;
    border-radius: 4px;
    height: 16.3466796875px;
    width: 58px;

    border-radius: 4px;

    margin-right: 50px;
    align-self: flex-start;
    margin-top: 1vh;
  }

  .plane-two {
    background: #fff49a;
  }

  .plane-three {
    background: #ffb0d5;
  }

  .what-plan-heading-row {
    display: flex;
    justify-content: flex-start;
    justify-content: flex-start;
  }

  .hr-white {
    border: 3px solid #ffffff;
    margin-top: 3vh;
  }

  .what-content {
    font-size: 2vh;
    line-height: 3vh;
    width: 40vh;
  }

  /*  -----------------------------------------

                
   
                why css goes here



  --------------------------------------------- */
  #why-inner {
    width: 1425px;
    margin: auto;
    font-family: Rakkas;
    background: #fff9ea;
    height: auto;
    padding-top: 3vh;
  }

  #whytheymad {
    background: #fff9ea;
  }

  .why-h1 {
    font-family: Rakkas;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 60px;
    /* identical to box height, or 125% */

    text-align: center;

    color: #1e266d;
  }

  .why-flex {
    display: flex;
    justify-content: center;
  }

  .why-main-text {
    margin: auto;
    margin-top: 2vh;
    font-family: PT Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 150%;
    /* or 36px */
    height: 147px;
    width: 80%;
    left: 206px;

    text-align: center;

    color: #455880;
  }

  .why-mad-images {
    display: flex;
    justify-content: space-evenly;
    justify-items: center;
  }

  .article {
    font-family: PT Sans;
    width: 80%;
    margin: auto;
    color: #455880;
    font-size: 21px;
    font-weight: normal;

    line-height: 150%;
  }

  .vl {
    background-color: #c80000;
    color: #c80000;
    -webkit-transform: rotate(90deg);

    border: 2px;
  }

  .btn-round {
    font-family: Rakkas;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 100%;
    /* or 18px */

    align-items: center;
    text-align: center;
    background: #3751ff;
    border-radius: 100px;
    color: #ffffff;
    height: 41.142578125px;
    width: 186px;
    border-radius: 100px;
  }

  .why-i-sinned {
    font-family: PT Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 150%;
    /* or 31px */

    text-align: center;

    color: #455880;
  }

  .why-img {
    margin-top: 10px;
    width: 500px;
    height: 350px;
    border-radius: 0px;
  }

  /*  -----------------------------------------

                
   
                epochs css goes here



  --------------------------------------------- */

  #epoch-inner {
    width: 1425px;
    margin: auto;
    height: auto;
    padding-left: 90px;
    padding-right: 90px;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-bottom: 2rem;
  }

  #epoch {
  }

  .card {
    width: 400px;
  }

  .card-body {
    text-align: center;
  }

  .card-body .p-tag {
    margin-top: 1vh;

    font-family: PT Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 180%;
    /* or 32px */

    text-align: center;
    text-transform: uppercase;

    color: #455880;
  }

  .epoch-h1 {
    margin-top: 15px;
    font-family: Rakkas;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 60px;
    /* or 125% */

    text-align: center;

    color: #1e266d;
  }

  .epoch-placeholder {
    height: auto;
    width: 60%;

    border-radius: 0px;
  }

  .epoch-h2 {
    font-family: Rakkas;
    font-style: normal;
    font-weight: normal;
    font-size: 35px;
    line-height: 60px;
    /* or 125% */

    text-align: center;

    color: #1e266d;
  }

  /* Rairites css
 /*  -----------------------------------------

                
   
                rarities css goes here



  --------------------------------------------- */

  #rarities-inner {
    width: 1425px;
    margin: auto;
    margin-top: 10px;
    padding-bottom: 6vh;
    background: #ffe083;
    text-align: center;
    height: auto;
  }

  #rarities {
    background: #ffe083;
  }

  .main-text {
  }

  .r-h1 {
    padding-top: 2rem;
    font-family: Rakkas;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 60px;
    /* or 125% */

    text-align: center;

    color: #1e266d;
  }

  .r-h2 {
    margin: auto;
    font-family: Rakkas;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 60px;
    /* or 125% */
    width: 60%;
    text-align: center;
    margin-bottom: 30px;
    color: #1e266d;
  }

  .r-variation-text {
    font-family: Rakkas;
    font-style: normal;
    font-weight: bold;
    font-size: 1vh;
    line-height: 27px;
    /* or 75% */

    color: #000000;
  }

  .r-input-num {
  }

  .rarities-holder {
    height: 900px;
    position: relative;
    text-align: left;
    background: rgba(255, 255, 255, 0.38);
    border-radius: 30px;
    width: 80%;
    margin: auto;
    color: black;
  }

  .r-variations {
    padding: 6vh;
    font-weight: bolder;
    display: flex;
    justify-content: space-around;
  }

  .r-text-input {
    background: #b5f2e3;
    border-radius: 50px;
    width: 368px;
    height: 45.92px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .r-text-input p,
  .r-text-input-white p {
    font-family: PT Sans;
  }

  .r-text-input-white {
    background: white;
    border-radius: 50px;
    width: 368px;
    height: 45.92px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .r-outfit {
    position: absolute;
    width: 364px;
    height: 173px;
    bottom: 0;
    left: 80px;
  }

  .r-mouth {
    position: absolute;
    width: 172px;
    height: 88px;
    top: 80px;
    left: -100px;
  }

  .r-eyes {
    position: absolute;
    width: 152px;
    height: 101px;
    bottom: 250px;
    left: 100px;
  }

  .r-glasses {
    position: absolute;
    width: 242px;
    height: 95px;
    top: -20px;
    right: 0;
  }

  .r-headgear {
    position: absolute;
    width: 200px;
    height: 200px;
    bottom: 100px;
    right: -90px;
  }

  .not-mad-enough-inner {
    height: 100%;
    width: 1425px;
    margin: auto;
    background: rgb(255, 233, 243);

    padding: 100px;
    margin-bottom: 4vh;
  }

  .not-mad-enough {
    width: 100%;
    background: rgb(255, 233, 243);
  }

  .discord-flex {
    display: flex;
    justify-content: space-between;
    padding: 30px;
  }

  .discord-container {
    background: #ffb0fe;
    border-radius: 18px;
  }

  .discord-container-h1 {
    text-align: left;
    font-family: Rakkas;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 60px;
    /* or 125% */

    color: #1e266d;
  }

  .mad-discord-btn {
    height: 56px;
    width: 228px;
    border-radius: 100px;
    font-family: PT Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 110%;
    /* or 26px */
    background: #b5f2e3;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    text-align: center;
    border: none;
    color: #000000;
    margin-bottom: 10px;
  }

  .mad-discord-btn:hover {
    color: #40c1a2;
    background: #ffffff;
    box-shadow: 0px 4px 20px #b5f2e3;
    border-radius: 50px;
    transition: 0.3s;
  }

  .mad-discord-btn:active {
    color: #40c1a2;
    background: #ffffff;
    border: 1px solid #b5f2e3;
    box-sizing: border-box;
    box-shadow: 0px 4px 20px rgba(104, 109, 224, 0.5);
    border-radius: 50px;
  }

  .btn-container-d {
    align-self: center;
  }

  .d-small {
    font-family: PT Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 125%;
    /* identical to box height, or 20px */

    text-align: right;

    color: #455880;
  }

  /*  -----------------------------------------

                
   
                faq css goes here



  --------------------------------------------- */

  #faq-inner {
    font-family: PT Sans;
    width: 1425px;
    margin: auto;

    font-family: Rakkas;
  }

  .faq-h1 {
    font-family: Rakkas;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 60px;
    /* or 125% */

    text-align: center;

    color: #1e266d;
  }

  .faq-h2 {
    font-family: Rakkas;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 60px;
    /* or 125% */

    text-align: center;

    color: #1e266d;
  }

  .faq-container {
    width: 80%;
    margin: auto;
  }

  .to-top {
    text-align: right;
    width: 80%;
  }

  .single-faq-box {
    background: #ffffff;
    border: 0.2px solid rgb(159, 159, 159);
    border-bottom: none;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    height: auto;
    text-align: left;
    font-family: PT Sans;
    font-style: normal;
    font-size: 18px;
    line-height: 60px;
    /* or 333% */

    color: #000000;
    padding-left: 1rem;
    margin-top: 1rem;
  }

  .faq-goodbyes {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 100px;
  }

  .outro {
    width: 250px;
    text-align: left;
    font-family: PT Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 145%;
    /* or 26px */

    color: #455880;
  }

  .faq-journey {
    font-family: PT Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 225%;
    /* or 40px */

    color: #455880;
  }

  .faq-breakline {
    border: 1px solid rgba(135, 146, 161, 0.08);
    margin: auto;
    width: 60%;
    margin-top: 8vh;
  }

  .footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 5rem;
    padding-bottom: 5rem;
  }

  .copyright {
    font-family: "PT Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 27px;
    /* or 193% */

    color: #455880;
  }

  .signature {
    font-family: "PT Sans";
    font-family: PT Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 27px;
    /* or 169% */

    text-align: center;

    color: #455880;
  }

  .privacy {
    text-decoration: none;
    cursor: pointer;
    font-family: PT Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 27px;
    /* or 193% */

    text-align: right;

    color: #455880;
  }
  .privacy:hover {
    color: #455880;
  }

  .terms-of-use {
    text-decoration: none;
    font-family: "PT Sans";
    color: #455880;
    cursor: pointer;
    margin-left: 2rem;
  }
  .terms-of-use:hover {
    color: #455880;
  }
  .p-policy {
    text-decoration: none;
    font-family: "PT Sans";
    color: #455880;
    cursor: pointer;
  }
  .p-policy:hover {
    color: #455880;
  }

  .d-left {
    text-align: left;
  }

  /*  Minting page css    */
  .sale-info-banner {
    font-family: Rakkas;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 150%;
    /* identical to box height, or 36px */
    height: 50px;
    text-align: center;
    background: #ff6f77;
    color: #ffffff;
    padding-top: 0.5rem;
  }
  .minting-menu {
    font-family: Rakkas;
    margin: auto;
    overflow: hidden;
    color: #1e266d;
    background: #b5f2e3;
    height: 85px;
    transition: 0.5s;
  }
  .menu-height {
    height: 100px;
  }
  .minting-page-h2 {
    font-family: Rakkas;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 105%;
    /* or 50px */

    text-align: center;

    color: #1e266d;
  }

  .checklists {
    display: flex;
    justify-content: space-around;
    text-align: left;
    font-family: PT Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 150%;
    /* or 27px */
    margin-top: 3rem;
    color: #455880;
  }

  .checklists .first-list .fas {
    margin-right: 2rem;
    position: static;
    position: initial;
  }

  .checklists .second-list .fas {
    position: static;
    position: initial;
    margin-right: 2rem;
  }
  .m-p-second-line {
    margin-right: 3rem;
  }

  /* -----------------------

  css for the intermediate page

  ----------------------- */

  #intermediate {
    height: 100vh;
    display: flex;
    justify-content: space-between;
  }

  #intermediate #img {
  }

  .open-sans {
    font-family: "Open Sans";
  }

  #intermediate #contents {
    flex-grow: 2;
  }

  .i-heading {
    margin-top: 1rem;
    font-family: Rakkas;
    font-style: normal;
    font-weight: normal;
    font-size: 64px;
    line-height: 105%;
    /* or 67px */

    text-align: center;

    color: #1e266d;
  }
  .i-subheading {
    margin-bottom: 3rem;
    font-family: Rakkas;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 105%;
    /* or 25px */

    text-align: center;

    color: #1e266d;
  }

  .i-info {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;

    color: #424242;
  }

  .middle-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 470px;
    height: 247px;
    margin: auto;
    margin-top: 2rem;
    padding: 2rem;
    border: 3px solid #3397da;
    box-sizing: border-box;
    box-shadow: 0px 0px 16px rgba(173, 180, 180, 0.24);
    border-radius: 50px;
  }

  .middle-text-container .middle-text-heading {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    text-align: center;

    color: #36407d;
  }
  .i-progressbar {
    width: 465px;
    margin: auto;
    margin-top: 1rem;
    border-radius: 10px;
  }

  .i-eyes {
    position: relative;
    left: 200px;
    top: 20px;
  }
  .i-mouth {
    position: relative;
    right: 350px;
    top: 350px;
  }
  .i-footer {
    margin-top: 40px;
    color: #424242;
  }
  .i-hr {
    width: 272px;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #3397da;
  }
}

/* small screens  */

@media only screen and (max-width: 1330px) and (min-width: 920px) {
  .menu-items {
  }

  .home-m-cards {
    display: flex;
    justify-content: space-around;
    width: 95%;
  }

  .home-m-card-item {
    width: 19.5%;
    text-align: center;
    font-size: 13px;

    margin-top: 2rem;
  }

  #home {
    height: auto;
  }

  #what {
    padding-top: -20rem;
  }

  .why-img {
    width: 400px;
    height: 400px;
  }

  .fa-check {
  }
}

@media only screen and (max-width: 1425px) and (min-width: 920px) {
  #home-inner,
  #faq-inner,
  #why-inner,
  #what-inner,
  #rarities-inner,
  #epoch-inner,
  .not-mad-enough-inner,
  .community-page-inner {
    width: 89.0625vw;
  }
}

/* 
**********************************************************************

                Mobile css goes ::-webkit-validation-bubble-arrow



*********************************************************************** */

/* General mobile devices css */

@media only screen and (max-width: 919px) {
  .community-page-h1 {
    font-family: Rakkas;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 79px;
    /* identical to box height, or 165% */

    text-align: center;

    color: #1e266d;
  }

  .community-page-message {
    font-family: PT Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 27px;
    /* or 150% */
    width: 80%;
    margin: auto;
    text-align: center;

    color: #455880;
  }

  .c-notion-page {
    font-family: PT Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 27px;
    text-decoration: none;
    text-align: center;
    margin-left: 3px;
    color: royalblue;
  }

  .alert-danger {
    background: rgb(255, 111, 119);
    color: white;
  }

  .checklists {
    font-family: PT Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 150%;
    /* or 27px */
    margin-top: 3rem;
    color: #455880;
  }

  .checklists .first-list .fas {
    margin-right: 2rem;
  }

  .checklists .second-list .fas {
    margin-right: 2rem;
  }
  .m-p-second-line {
  }
  .first-list,
  .second-list {
    display: flex;
    justify-content: center;
    text-align: left;
  }

  .second-list {
    margin-top: 2rem;
  }

  .sale-info-banner {
    font-family: Rakkas;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;

    /* identical to box height, or 36px */
    height: 50px;
    text-align: center;
    background: #ff6f77;
    color: #ffffff;
    padding-top: 0.5rem;
  }
  .open-sans {
    font-family: "Open Sans";
  }
  #main {
    text-align: center;
  }

  .navbar {
    background-color: rgb(181, 242, 227);
    padding-bottom: 0px;
  }

  .navbar-nav a {
    font-family: Rakkas;
    color: #1e266d;
  }

  .navbar-nav a:active {
    color: rgb(255, 46, 106);
  }

  .desktop-only {
    display: none;
  }

  .i-mad-main,
  .checkmark,
  .bgv,
  .dude_two,
  .dude_three,
  #epochs {
    display: none;
  }

  .menu-btn {
    width: 100px;
    height: 30px;
    box-shadow: 0px 4px 20px rgba(104, 109, 224, 0.5);
    border-radius: 10px;
    border: none;
    background: #3751ff;
    color: white;
  }

  .menu-btn:active {
    color: #3751ff;
    background: #ffffff;
    box-shadow: 0px 4px 20px #3751ff;
    border-radius: 10px;
  }

  .dude_one {
    width: 100%;
  }

  /* timer css */
  .timer {
    height: 100%;
    margin-bottom: -20px;
  }

  .timmer-inner p {
    font-family: Rakkas;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    padding: 1rem 0rem;
    line-height: 150%;
    /* or 54px */

    text-align: center;

    color: rgb(255, 111, 119);
  }

  /*  Navigation menu css  */
  .navbar {
    overflow: hidden;
    color: #1e266d;
    background: #b5f2e3;
    width: 100%;
    height: auto;
  }

  .navbar-nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
  }

  .menu-items {
    font-family: Rakkas;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    line-height: 5em;
    /* or 472% */

    letter-spacing: 0.095em;

    color: #1e266d;
  }

  .menu-items li {
    list-style: none;
    font-weight: bolder;
  }

  .transparent {
    opacity: 0.9;
  }

  .menu-items li a {
    text-decoration: none;
    color: #44598b;
    font-weight: normal;
  }

  /* Home css */

  .mobile-social-icons {
    display: flex;
    justify-content: center;
  }

  .twitter-mobile {
    margin-right: 1rem;
  }

  #home {
    padding-top: 2rem;
    width: 100%;
    height: 100%;
    background: center rgb(255, 236, 145);
    border-radius: 0px;
  }

  .home-h1 {
    margin: auto;
    height: 45px;
    width: 305.3158264160156px;

    font-family: Rakkas;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 105%;
    /* or 38px */
    margin-bottom: 20px;
    text-align: center;

    color: #1e266d;
  }

  .metamask-btn {
    font-family: PT Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    margin-left: 10px;
    line-height: 40px;
    /* identical to box height, or 222% */

    text-align: center;
    text-transform: uppercase;

    color: #ffffff;
    background: #fa7106;
    border-radius: 100px;
    width: 211px;
    height: 50px;
    border: none;
    padding: 5 px;
  }

  .metamask-btn:hover {
    color: #ff6f77;

    background: #ffffff;
    border: 1px solid #ff6f77;
    box-sizing: border-box;
    box-shadow: 0px 4px 20px rgba(104, 109, 224, 0.5);
    border-radius: 50px;
  }

  .home-minting-btn {
    margin-bottom: 20px;
    cursor: pointer;
    height: 58px;
    width: 316px;
    border-radius: 20px;
    font-family: Rakkas;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 150%;
    /* or 54px */

    text-align: center;
    background: rgba(255, 46, 106, 0.66);
    border-radius: 20px;
    color: #ffffff;
    border: none;
  }

  .home-minting-btn:hover {
    color: #ff6f77;

    background: #ffffff;
    border: 1px solid #ff6f77;
    box-sizing: border-box;
    box-shadow: 0px 4px 20px rgba(104, 109, 224, 0.5);
    border-radius: 20px;
  }

  .home-minting-btn:active {
    color: #ff6f77;

    background: #ffffff;
    box-shadow: 0px 4px 20px #ff6f77;
    border-radius: 20px;
  }

  .metamask-btn:active {
    color: #ff6f77;

    background: #ffffff;
    box-shadow: 0px 4px 20px #ff6f77;
    border-radius: 50px;
  }

  .home-discord-btn {
    text-align: center;
    cursor: pointer;
    background: #3751ff;
    border-radius: 100px;
    font-family: Rakkas;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 100%;
    /* or 36px */
    height: 50px;
    width: 316px;
    border-radius: 100px;

    text-align: center;

    color: #ffffff;
    margin: auto;
    border: none;
  }

  .home-discord-btn:hover {
    color: #3751ff;

    background: #ffffff;
    border: 1px solid #3751ff;
    box-sizing: border-box;
    box-shadow: 0px 4px 20px rgba(104, 109, 224, 0.5);
    border-radius: 50px;
  }

  .btn-article:hover {
    color: #3751ff;

    background: #ffffff;
    border: 1px solid #3751ff;
    box-sizing: border-box;
    box-shadow: 0px 4px 20px rgba(104, 109, 224, 0.5);
    border-radius: 50px;
  }

  .home-discord-btn:active {
    color: #3751ff;
    background: #ffffff;
    box-shadow: 0px 4px 20px #3751ff;
    border-radius: 50px;
  }

  .home-pricing-text,
  .home-gas-text {
    font-family: Rakkas;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 150%;
    /* or 54px */

    text-align: center;

    color: #455880;
    height: 56.147300720214844px;
    width: 305.8341979980469px;
    border-radius: nullpx;
  }

  .home-gas-text {
    font-size: 1.2rem;
  }

  .single-mad-doc {
    position: absolute;
    width: 315px;
    height: 315px;
    left: 51px;
    top: 441px;
  }

  /* What are mad scientists css  */

  #what {
    padding-top: 2rem;
    background-color: white;
    height: 100%;
    width: 100%;
  }

  .plane-one,
  .plane-two,
  .plane-three {
    background: #aedeff;
    border-radius: 4px;
    height: 2vh;
    width: 8vh;
    margin-right: 8vh;
    align-self: flex-start;
    margin-top: 1vh;
  }

  .plane-two {
    background: #fff49a;
  }

  .plane-three {
    background: #ffb0d5;
  }

  .what-plan-heading-row {
    padding: 2rem;
    text-align: left;
    display: flex;
    justify-content: center;
  }

  .hr-white {
    border: 3px solid #ffffff;
    margin-top: 3vh;
  }

  .what-content {
    font-size: 2vh;
    line-height: 3vh;
    width: 40vh;
  }

  .what-plane-equal-heading {
    font-family: Rakkas;
    font-size: 3vh;
  }

  .what-plan-heading-p {
    font-family: PT Sans;
  }

  /* Why are mad scientists css  */

  #whytheymad {
    padding-top: 2rem;
    background-color: white;
    height: 100%;
    width: 100%;
    padding-bottom: 2rem;
  }

  .why-h1 {
    font-family: Rakkas;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 60px;
    /* identical to box height, or 125% */

    text-align: center;

    color: #1e266d;
  }

  .btn-article {
    width: 165px;
    height: 41px;
    border: none;
    color: white;
    background: #3751ff;
    border-radius: 100px;
  }

  .btn-article:active {
    color: #3751ff;
    background: #ffffff;
    box-shadow: 0px 4px 20px #3751ff;
    border-radius: 50px;
  }

  .why-main-text {
    font-family: PT Sans;
    font-style: normal;
    font-weight: normal;
    width: 18rem;
    margin: auto;
    font-size: 24px;
    line-height: 150%;
    /* or 36px */

    text-align: center;

    color: #455880;
  }

  .btn-round {
    border-radius: 100px;
    font-family: Rakkas;
    font-style: normal;
    font-weight: normal;
    font-size: 3vh;
    line-height: 100%;
    /* or 18px */

    display: flex;
    align-items: center;
    text-align: center;
    margin: auto;
    color: #ffffff;
  }

  /* Rarities css  */

  #rarities {
    padding-top: 2rem;
    background: #ffb0d5;
  }

  .r-headgear {
    width: 222px;
    height: 249px;
  }

  .r-h1-mobile {
    font-family: Rakkas;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 60px;
    /* or 125% */

    text-align: center;
    color: #1e266d;
  }

  .r-h2-mobile {
    font-family: Rakkas;
    font-style: normal;
    font-weight: normal;
    font-size: 35px;
    line-height: 60px;
    /* or 125% */

    text-align: center;

    color: #1e266d;
  }

  .rarities-holder {
  }

  .r-variations {
    text-align: left;
    margin: auto;
    font-weight: bolder;
    font-size: 1.2rem;
    line-height: 60px;
  }

  .r-varitaion-text {
    margin-bottom: 2rem;
    margin-top: 2rem;
    font-family: Rakkas;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 27px;
    /* or 75% */

    color: #000000;
  }

  .r-text-input {
    margin: auto;
    background: #b5f2e3;
    border-radius: 50px;
    width: 90%;
    height: 45.92px;
    display: flex;
    justify-content: space-between;

    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
  }

  .r-text-input-white {
    margin: auto;
    background: white;
    border-radius: 50px;
    width: 90%;
    height: 45.92px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
  }

  .r-flex-mobile {
    display: flex;
    justify-content: space-between;
  }

  /* faq page css */
  #faq {
    padding-top: 3rem;
    background-color: white;
  }

  .faq-h1 {
    font-family: Rakkas;
    font-style: normal;
    font-weight: normal;
    font-size: 58px;
    line-height: 60px;
    /* or 125% */

    text-align: center;

    color: #1e266d;
  }

  .faq-h2 {
    font-family: Rakkas;
    font-style: normal;
    font-weight: normal;
    font-size: 38px;
    line-height: 60px;
    /* or 125% */

    text-align: center;
    width: 60%;
    margin: auto;
    color: #1e266d;
  }

  .faq-container {
    width: 100%;
    margin: auto;
    padding: 1rem;
  }

  .collapse-text {
    font-family: "PT Sans";
    font-weight: normal;
  }

  .single-faq-box {
    background: #ffffff;
    border: 0.5px solid black;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: left;
    font-size: 1rem;
    padding: 0.5rem;
    font-weight: bold;
    font-family: "PT Sans";
    margin-bottom: 1rem;
  }

  .copyright {
    font-family: PT Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 27px;
    /* or 193% */

    color: #455880;
  }

  .signature {
    font-family: PT Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 27px;
    /* or 169% */

    text-align: center;

    color: #455880;
  }

  .privacy {
    font-family: PT Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 27px;
    /* identical to box height, or 193% */

    text-align: center;

    color: #455880;
  }

  .terms-of-use {
    margin-left: 2rem;
  }

  .outro {
    width: 70%;
    margin: auto;
    font-family: PT Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 145%;
    /* or 26px */

    text-align: center;

    color: #455880;
    /* or 26px */
    text-align: center;
    color: #455880;
  }

  .faq-journey {
    margin-top: 3rem;
    font-family: PT Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 2vh;
    line-height: 225%;
    /* or 40px */

    color: #455880;
  }

  .r-outfit {
    margin-top: 3rem;
  }

  .collapse {
    font-family: PT Sans;
    font-weight: normal;
  }
  .privacy:hover {
    color: #455880;
  }

  .terms-of-use {
    text-decoration: none;
    font-family: "PT Sans";
    color: #455880;
    cursor: pointer;
    margin-left: 2rem;
  }

  .terms-of-use:hover {
    color: #455880;
  }

  .p-policy {
    text-decoration: none;
    font-family: "PT Sans";
    color: #455880;
    cursor: pointer;
  }

  .p-policy:hover {
    color: #455880;
  }

  /* intermediate page css */

  #intermediate {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  #intermediate #img {
  }

  #intermediate #contents {
    flex-grow: 2;
  }

  .i-heading {
    margin-top: 1rem;
    font-family: Rakkas;
    font-style: normal;
    font-weight: normal;
    font-size: 3.5rem;
    line-height: 105%;
    /* or 67px */

    text-align: center;

    color: #1e266d;
  }
  .i-subheading {
    margin-bottom: 3rem;
    font-family: Rakkas;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 105%;
    /* or 25px */

    text-align: center;

    color: #1e266d;
  }

  .i-info {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;

    color: #424242;
  }

  .middle-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 320px;
    height: 180px;
    margin: auto;
    margin-top: 2rem;
    border: 3px solid #3397da;
    box-sizing: border-box;
    box-shadow: 0px 0px 16px rgba(173, 180, 180, 0.24);
    border-radius: 50px;
  }

  .middle-text-container .middle-text-heading {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 33px;
    text-align: center;
    color: #36407d;
  }
  .i-progressbar {
    width: 90%;
    margin: auto;
    margin-top: 1rem;
    border-radius: 10px;
  }

  .i-main-img {
    width: 138px;
    height: 178px;
  }
  .i-footer {
    position: relative;
    top: 100px;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .i-eyes {
    display: none;
  }
  .i-mouth {
    display: none;
  }
}

/* Extra small screen size  */

@media only screen and (max-width: 410px) {
  .r-text-input {
    margin: auto;
    background: #b5f2e3;
    border-radius: 50px;
    width: 97%;
    height: 45.92px;
    display: flex;
    justify-content: space-between;

    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
  }

  .r-text-input-white {
    margin: auto;
    background: white;
    border-radius: 50px;
    width: 97%;
    height: 45.92px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
  }
  .r-text-input p,
  .r-text-input-white p {
    font-size: 4.5vw;
  }
  .r-outfit {
    width: 300px;
  }
}

@media only screen and (min-height: 800px) {
  .i-main-img {
    height: 100vh;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

